import { Link } from "gatsby";
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import ClientsForm from "../../../../src/components/clientsForm";
import Title from "../../../../src/components/title";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { FaRegWindowClose } from "react-icons/fa";
import React from 'react';
export default {
  Link,
  Layout,
  SEO,
  ClientsForm,
  Title,
  ModalRoutingContext,
  FaRegWindowClose,
  React
};