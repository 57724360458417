import React from "react"
import TitleImage from "./titleImage"

const Title = props => {
  return (
    <>
    <div id="title" style={{ width: `100%`, marginBottom: 0, paddingBottom: 0 }}>
        <TitleImage className="title-image" filename={props.filename}/>
        <div className="overlay" style={{ backgroundColor: `gray`, }}></div>
        <h1>{props.title}</h1>
        <p>{props.calltoaction}</p>
    </div>
    </>
  )
}

export default Title
