// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-clients-mdx": () => import("./../src/pages/clients.mdx" /* webpackChunkName: "component---src-pages-clients-mdx" */),
  "component---src-pages-providers-mdx": () => import("./../src/pages/providers.mdx" /* webpackChunkName: "component---src-pages-providers-mdx" */),
  "component---src-pages-caregivers-mdx": () => import("./../src/pages/caregivers.mdx" /* webpackChunkName: "component---src-pages-caregivers-mdx" */)
}

