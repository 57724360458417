import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
    <div id="outer-container">
      <Header />
      
      <div
        style={{
          margin: `5rem auto`,
          marginBottom: 0,
          paddingTop: `1.0875rem`,
        }}
      >
        
        <main id="page-wrap">{children}</main>
        <footer>
          <span>© {new Date().getFullYear()} Myriad Healthcare Staffing Services.  All rights reserved.</span>
        </footer>
      </div>
    </div>
  )

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
