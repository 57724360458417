import React from "react"
import { useSiteSearchIndex } from "../hooks/searchIndex"
import styles from "./header.module.css"
import Nav from "./nav"
import SearchIcon from '@material-ui/icons/Search'
import { IconButton, Collapse } from '@material-ui/core'

import { MdCall, MdEmail, MdLocationCity } from "react-icons/md"
import { FaFacebook, FaFax } from "react-icons/fa"
import Search from "./search"
import Logo from "./logo"


const Header = () => {
  const { index } = useSiteSearchIndex()
  const [toggled, setToggle] = React.useState(false);

  const handleClick = () => {
    setToggle(prev => !prev);
  };
  
  return (

  <header
    style={{
      background: `white`,
      position: `sticky`,
      top: `0`,
      zIndex: 1,
    }}
  >
    <div
      className={ styles.banner }
      style={{
        backgroundColor: `#000099`,
        color: `#fff`,
        padding: `.3rem 1.0875rem`,
        
      }}
    >
          <MdCall aria-hidden="true"/> 
          <a href="tel:1-213-422-6643" title="Call Myriad office">(213) 422-6643</a>
          <FaFax aria-hidden="true" className={ styles.bannerIcon } /> 
          <span title="Fax Myriad office">(626) 486-2108</span>
          <MdEmail aria-hidden="true" className={ styles.bannerIcon }/>
          <a href="mailto:myriadstaffs@yahoo.com" title="Send email to Myriad">myriadstaffs@yahoo.com</a>
          <MdLocationCity aria-hidden="true" className={ styles.bannerIcon }/>
          <span>171 N. Altadena Drive, Ste 215, Pasadena, CA 91107</span>
        <div id="right"> 
        <IconButton onClick={handleClick} style={{ color: `#fff`, marginRight: `5px`, marginBottom: `5px`}} title="Hide/Show Search Bar">
          <SearchIcon/> 
        </IconButton>
        
            <a style={{ color: `#fff`, }} href="https://www.facebook.com/myriadstaffs/" title="Visit facebook page"><FaFacebook style={{ fontSize: `1.3em`, }}/></a> 
        </div>
 
        <Collapse in={toggled} style={{ display: `flex`, flexWrap: 'wrap'}}>
          <Search searchIndex={index} className={styles.search} inputProps={{ className: styles.search }}/>
        </Collapse>
  
    </div>
    <div 
    style={{
        backgroundColor: `white`,
        position: `absolute`,
        height: `100px`,
        width: `100%`
      }}>
      <div
        style={{
          backgroundColor: `white`,
          padding: `.6rem .2rem 0 1.0875rem`,
          position: `absolute`,
          width: `330px`,
        }}
      >
        <Logo color="#000099" />
      </div>
      <Nav />
    </div>
  </header>
)}

export default Header
