import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { useSiteMetadata } from "../hooks/siteMetaData"

const Logo = ({ backgroundColor, color}) => {

    const { title } = useSiteMetadata()
    const data = useStaticQuery(graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    
  
    return (
      <Link
          to="/"
          style={{
            backgroundColor,
            color,
            fontWeight: `bold`,
            textDecoration: `none`,
          }}
        >
          <div style={{ maxWidth: 150, }}>
          <Img fluid={data.logo.childImageSharp.fluid} />
          </div>
          {title}
        </Link>
      )
  }

  export default Logo