import React from "react"

const ProvidersForm = () => (
  <>
    <form name="providers" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success" encType="multipart/form-data">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="providers" />
      <div className="field">
        <label htmlFor="name">Name*</label>
        <input type="text" name="name" id="name" required />
      </div>
      <div className="field" id="phone-info">
        <label htmlFor="phone">Phone Number*</label>
        <input type="tel" name="phone" id="phone" pattern="\d{3}-\d{3}-\d{4}" style={{ width: `20%` }} required /> <small>Format: 123-456-7890</small>
        <label htmlFor="extension" id="ext">ext.</label>
        <input type="number" name="extension" id="extension" min="1" pattern="\d{1,5}" style={{width: `10%`}} /> <small>(optional)</small>
      </div>
      <div className="field">
        <label htmlFor="email">Email*</label>
        <input type="text" name="email" id="email" required />
      </div>
      <div className="field">
        <label htmlFor="preferred_contact_method">What is the best way to contact you?*</label>
        <input type="radio" name="preferred_contact_method" value="call" defaultChecked/><span>Call</span>
        <input type="radio" name="preferred_contact_method" value="email" /><span>Email</span>
        <input type="radio" name="preferred_contact_method" value="text" /><span>Text</span>
      </div>
      <div className="field">
        <label htmlFor="role">I am applying as a(n)*</label>
        <select name="role" id="role" required>
            <option value="CNA">CNA</option>
            <option value="caregiver">Caregiver</option>
            <option value="LVN">LVN</option>
            <option value="OT">OT</option>
            <option value="OTA">OTA</option>
            <option value="PT">PT</option>
            <option value="PTA">PTA</option>
            <option value="RN">RN</option>
            <option value="SLP">SLP</option>
            <option value="SLPA">SLPA</option>
        </select>
      </div>
      <div className="field">
        <label htmlFor="certifications">Certifications</label>
        <textarea name="certifications" id="certifications" rows="3"/>
      </div>
      <div className="field">
        <label htmlFor="resume">Resum&eacute; (optional)</label>
        <input type="file" name="resume" id="resume" />
      </div>
      <div className="field">
        <label htmlFor="message">Message*</label>
        <textarea name="message" id="message" rows="6" />
      </div>
      <p className="muted-text">*required</p>
      <ul className="actions">
        <li>
          <button type="submit">Send Application</button>
        </li>
      </ul>
    </form>
  </>
)

export default ProvidersForm
