import React from "react"
import { Link } from "gatsby"

import InboxIcon from '@material-ui/icons/Inbox'
import PeopleIcon from '@material-ui/icons/People'
import AssessmentIcon from '@material-ui/icons/Assessment'

const NavList = ({ menu }) => {
    const getLink = icon => {
        switch(icon){
            case 'inbox':
                return <InboxIcon/>
            case 'people':
                return <PeopleIcon />
            case 'overview':
                return <AssessmentIcon />
            default:
                return null
        }
    }
    return (
        <>
          {menu.map(link => (
            <Link style={{ 
                        textDecoration: `none`,
                    }} 
                    to={link.link} 
                    activeStyle={{ 
                        color: "orange",
                    }}
                    key={link.name}>
                <li
                    className='nav-selection'
                    key={link.name}
                    style={{
                        display: `block`,
                        padding: `1rem 1.5rem`,
                        textTransform: `capitalize`,
                        
                    }}
                >
                    <span style={{ paddingRight: '25px' }}>{getLink(link.icon)}</span>
                    {link.name}
                </li>
                </Link>
            ))}
        </>  
)}

export default NavList