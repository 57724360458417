import React from "react"
import { Link } from "gatsby"
import styles from "./header.module.css"
import { slide as Menu } from 'react-burger-menu'

import { FaBars, FaRegWindowClose } from "react-icons/fa"
import { useSiteMetadata } from "../hooks/siteMetaData"
import NavList from "./navList"

const Nav = () => {
    const { menuLinks } = useSiteMetadata()
    return (
    <Menu right isOpen={ false }
        burgerButtonClassName={ styles.bmBurgerButton }
        className={ styles.bmItemList }
        customBurgerIcon={ <FaBars title="Open window"/> }
        customCrossIcon={ <FaRegWindowClose title="Close window"/> }
        pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }
    >
        <NavList menu={menuLinks}/>
    </Menu>
)}

export default Nav
