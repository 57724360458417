import { useStaticQuery, graphql } from "gatsby"

export const useSiteSearchIndex = () => {
  const data = useStaticQuery(graphql`
    query SiteSearchQuery {
      siteSearchIndex {
        index
      }
    }
  `)
  return data.siteSearchIndex
}