import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import { TextField } from '@material-ui/core'



// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div id="search" style={{width: `100%`}}>
        <TextField type="text" value={this.state.query} onChange={this.search} placeholder="Search myriadstaffs.com" className={this.props.className} inputProps={this.props.inputProps} variant={this.props.variant} />
        <ul style={{ listStyleType: `none`, backgroundColor: `white`, zIndex: `1002`}}>
          {this.state.results.map(page => (
            <li key={page.id} aria-live="polite" style={{ padding: `5px 0`,}}>
              <Link style={{ color: `#000099` }} to={"/" + page.path}>{page.title}</Link>
              {": " + page.description }
            </li>
          ))}
        </ul>
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}