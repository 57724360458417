import { Link } from "gatsby";
import Layout from "../../../../src/components/layout";
import Image from "../../../../src/components/image";
import SEO from "../../../../src/components/seo";
import CaregiversForm from "../../../../src/components/caregiversForm";
import Title from "../../../../src/components/title";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { FaRegWindowClose } from "react-icons/fa";
import React from 'react';
export default {
  Link,
  Layout,
  Image,
  SEO,
  CaregiversForm,
  Title,
  ModalRoutingContext,
  FaRegWindowClose,
  React
};